<template>
  <div>
    <!-- 교육계획별 대상자 엑셀업로드 -->
    <c-card title="LBL0002843" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- 적용 -->
          <c-btn 
            v-if="editable" 
            label="LBLAPPLY" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <!-- 요청부서/이름/일자 -->
          <c-text
            :editable="editable"
            :disabled="true"
            label="LBL0002844"
            name="request"
            v-model="request">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'education-curriculumn-excel-upload',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
        }
      },
    },
  },
  data() {
    return {
      excelUploadInfo: {
        rowKeys: ['userId'], // 데이터들의 키 속성값
        taskClassCd: 'EDU_TARGET_USERS', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            required: true,
            disabled: true,
            name: 'eduEducationId',
            field: 'eduEducationId',
            // 교육계획일련번호
            label: 'LBL0002845',
            align: 'center',
            style: 'width:130px',
          },
          {
            disabled: true,
            name: 'educationName',
            field: 'educationName',
            // 교육명
            label: "LBL0002929",
            align: 'center',
            style: 'width:250px',
          },
          {
            disabled: true,
            name: 'educationDate',
            field: 'educationDate',
            // 교육기간
            label: "LBL0002829",
            align: 'center',
            style: 'width:150px',
          },
          {
            required: true,
            disabled: true,
            name: 'empNo',
            field: 'empNo',
            // 사번
            label: 'LBL0002846',
            type: 'text',
            align: 'center',
            style: 'width:150px',
          },
          {
            required: true,
            disabled: true,
            name: 'userName',
            field: 'userName',
            // 이름
            label: 'LBLNAME',
            type: 'text',
            align: 'center',
            style: 'width:150px',
          },
        ]],
        data: [],
        deptCd: '',
        processCd: '',
        workplace: '',
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    request() {
      return this.$store.getters.user.deptName + ' / ' + this.$store.getters.user.userName + ' / '  + this.$comm.getToday()
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code 
    // list setting
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: 'MSG0000854', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSG0000855', // 에러가 있는 데이터가 존재합니다.\n\r에러 있는 데이터를 제외한 나머지 데이터를 적용하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            this.$emit('closePopup', this.excelUploadInfo.data[0]);
          }
        }
      }
    }
  }
};
</script>
